.physicalPlantConfig {
  margin-left: 10px;
  padding-top: 10px;
}
.PPCData {
  font-size: 0.75em;
  color: gray;
}
.splitterBackground {
  background-color: gainsboro;
  margin-right: 10px;
}

.button {
  height: 50px;
  width: 100px;
}
