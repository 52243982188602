.Colocations {
  width: 90%;
  height: 85vh;
  margin: auto;
}
.coloUl {
  margin: 10px 0;
}
.colomap {
  height: 800px;
  width: 100%;
}
@media only screen and (min-height: 320px) {
  .colomap {
    height: 55vh;
  }
}