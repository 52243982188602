.ServiceLocation {
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.item {
  padding: 10px 0;
  span {
    padding: 10px 0;
  }
}
.address {
  display: flex;
  flex-direction: column;
}
.package {
  border-bottom: 2px solid rgb(48, 45, 45);
}
.toggleScanner {
  max-width: 100px;
}