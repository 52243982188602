.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.fontSize {
  font-size: 1.25em;
}

.SavedOrders {
  border-bottom: 2px solid #302d2d;
  padding: 5px;
}
