
.keyDisplay {
  color: #361f63;
  font-weight: bold;
}
.title {
  color: orange;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}
.keyValue {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 400px) {
  .keyValue {
    margin: auto;
  }
}
.statDisplay{
  margin:auto;
  max-width:550px;
}
.circuitDisplay{
  padding:10px;
}
.circuitBody{
  padding-top: 5px;
}
.btnContainer{
  display:flex;
  padding-bottom: 10px;
}
.leftBtns{
  flex: 0 0 50%;
}
.rightBtns{
  flex: 1;
  text-align: right;
}