.Search,
.SearchHome {
  min-height: 100vh;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.formSearchScreen {
  min-height: 100vh;
}
.SearchResult {
  .right {
    display: flex;
    justify-content: flex-end;
  }
  .fontsize {
    font-size: 1.4em;
  }
  .space {
    display: flex;
    justify-content: space-between;
  }
}
.WOSearchResult {
  list-style: none;
  border-bottom: 2px solid rgb(48, 45, 45);
  padding: 10px 0;
  a {
    color: inherit;
    text-decoration: inherit;
  }
}
.WOSearchResult:last-of-type {
  border-bottom: none;
}

.SearchHome,
.WorkOrder {
  width: 100%;
  button {
    min-height: 30px;
  }
}
.spaceEvenly {
  display: flex;
  justify-content: space-evenly;
}

.centerText {
  display: flex;
  justify-content: center;
}

.flexEnd {
  align-content: flex-end;
}

.inlineBlock {
  display: inline-block;
}

.title {
  padding: 5px 20px;
  background-color: #4f2471;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  margin: auto;
  justify-content: center;
  font-size: 1.875em;
}

.allCheckBox {
  display: flex;
}
.itemCheckbox {
  width: 95%;
  display: flex;
  justify-content: space-between;
  span,
  label {
    width: 100%;
  }
  select {
    margin: 0 1em;
  }
  p {
    display: inline-block;
  }
}
.item {
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin-right: 10%;
}

.searchButton {
  -webkit-appearance: none;
  margin-left: 5px;
  max-width: 100px;
  height: auto;
  background-color: gainsboro;
}

.prefetch {
  -webkit-appearance: none;
  max-width: 300px;
  height: 25px;
  margin: 5px auto 5px 0;
  background-color: gainsboro;
}
.clearSearchBtn {
  -webkit-appearance: none;
  margin-left: 5px;
  max-width: 100px;
  height: 25px;
  background-color: gainsboro;
}

.WOTypes {
  width: max-content;
}

@media only screen and (max-width: 1024px) {
  .clearSearchBtn, .prefetch {
    margin: 0 auto;
  }
  .SearchHome {
    width: 90%;
  }
  .allCheckBox {
    display: block;
  }
  .Search {
    display: flex;
    margin: auto;
  }

  .displayGrid {
    margin: auto;
    width: 50%;
    justify-content: center;
  }
}

.deleteButton {
  width: 100px;
  height: 20px;
}
.borderBottom {
  list-style: none;
  border: 2px solid rgb(48, 45, 45);
  box-shadow: 5px 10px 5px #888888;
  padding: 5px;
  padding: 10px 5px;
  margin: 15px 5px;
  border-radius: 12px;
}

.borderBottom:hover{
  color: blue;
  background-color: #cfcfcf;
  box-shadow: none;
}

@media only screen and (min-width: 900px) {
  .formSearchScreen {
    width: 60%;
    margin: auto;
  }
  .Search {
    display: flex;
  }
}

.formPaddingTop {
  padding-top: 10px;
}

.PaddingBottom {
  padding-bottom: 10px;
}
.displayGrid {
  display: grid;
  max-width: 200px;
}
@media only screen and (max-width: 500px) {
  .displayGrid {
    display: grid;
    max-width: 200px;
  }
  .sortByDiv {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }
  .buttonWrapper {
    width: 50 px;
  }
}

@media only screen and (max-width: 1024px) {
  .Search button {
    margin-bottom: 4%;
  }
}
