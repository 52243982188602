.Assets, .Asset {
  margin: auto;
}
.Asset {
  width: 100%;
  min-height: 100%;
  .spinner {
    margin: auto;
  }
}
.WOActionEleDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
  button {
    max-width: 100px;
    padding-bottom: 10px;
  }
}
.assetLink {
  display: block;
  padding: 10px 0;
}

.displayMessage {
  color: red;
  font-weight: bold;
}

.WOActionDiv{
  padding-top: 5px;
}