.Contacts {
  .Contact {
    padding: 10px;
    width: 350px;
    span {
      margin-right: 20px;
    }
    border-bottom: 2px solid rgb(48, 45, 45);
  }
  .contactInfo {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
  }
}

@media only screen and (max-width: 300px) {
  .Contacts {
    width: 200px;
  }
}
