.fullScreenWrapper {
  width: 100%;
  height: 100%;
  display: flex;
}
.menuSidebar {
  border-right: 1px solid #c8c8c8;
}
.button {
  cursor: pointer;
  height: max-content;
  padding: 10px;
  margin: 0;
  transition: all 0.2s ease;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.875em;
  font-weight: 600;
  color: #606060;
  text-shadow: 0 1px #fff;
}

.button:hover {
  background-color: rgb(247, 250, 252);
}
.active,
.button:hover a {
  color: gray;
}
.textAlignCenter {
  text-align: center;
}

.flex_1_1_container {
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }
}

.currentBill {
  width: 100%;
  font-weight: 700;
  .highlight {
    color: #ff9e0f;
  }
  .buttonContainer {
    width: 150px;
    display: flex;
    margin: 20px 20px 5px auto;
  }
  .button {
    display: flex;
    text-decoration: none;
    border-radius: 0.75em;
    border: none;
    color: white;
    background: linear-gradient(
      rgba(54, 31, 99, 1),
      rgba(0, 0, 255, 1),
      rgba(54, 31, 99, 1)
    );
    background: rgba(0, 0, 255, 1);
    padding: 10px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    font-size: 0.875em;
    margin-left: auto;
  }
}
.configureButton {
  color: #ffffff;
  font-weight: 700;
  background-image: linear-gradient(
    rgb(137, 198, 98) 0%,
    rgb(107, 184, 59) 100%
  );
  border-radius: 10px;
  padding: 5px 20px;
  border-width: 1px;
}
.configureButton:hover {
  background-image: linear-gradient(
    rgb(107, 184, 59) 0%,
    rgb(137, 240, 80) 100%
  );
}
.mainContainer {
  display: flex;
  flex-direction: column;
  .flexItem {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
  }
  > .flexItem:first-of-type {
    margin-top: 20px;
  }
  > .flexItem:last-of-type {
    margin-top: 20px;
  }
}
.slider {
  width: 100%;
  min-height: 400px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  .active {
    background-color: #4f2471;
    color: #ffffff;
  }
  .header {
    height: 50px;
    border-bottom: 1px solid #c8c8c8;
    color: #4f2471;
    display: flex;
    justify-content: space-between;
    .button {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  .buttonGroups {
    padding: 10px;
    margin-top: auto;
  }
  .hidden {
    visibility: hidden;
  }
}
#button4 {
  border: none;
  background: none;
  cursor:initial; 
}
.spinner {
  margin: auto;
}
.ldsSpinner {
  color: palegreen;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsSpinner div {
  transform-origin: 40px 40px;
  animation: ldsSpinner 1.2s linear infinite;
}
.ldsSpinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fcf;
}
.ldsSpinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.ldsSpinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.ldsSpinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.ldsSpinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.ldsSpinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.ldsSpinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.ldsSpinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.ldsSpinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.ldsSpinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.ldsSpinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.ldsSpinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.ldsSpinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes ldsSpinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.WODisplayForm {
  width: 100%;
  overflow-x: auto;
  td,
   th {
      vertical-align: middle;
      border: 1px solid silver;
      border-right: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      background: #fff;
      color: #666666;
      padding: 5px;
      button {
        font-family: Arial, Verdana, sans-serif;
        background-color: #4f2471;
        color: #ffffff;
        font-size: 10pt;
        height: 35px;
        line-height: 130%;
        text-decoration: none;
        font-weight: bold;
        border: none;
        cursor: pointer;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }
    }
    .salesOrderDate {
      font-weight: bolder;
    }
    .customerName {
      min-width: max-content;
      width: max-content;
    }
    .WOType {
      width: max-content;
    }
    .packageName {
      width: max-content;
    }
  .WONumber {
    background: #EAEBFF;
  }
  .highlight {
    background-color: aqua;
  }
  table {
    width: max-content;
    margin: 10px 0;
    th, td {
      width: min-content;
    }
  }
  .maxWidth300 {
    max-width: 300px;
  }
}
.stringsToFilterDiv {
  margin: 50px 0;
}
.WODisplayForm:hover {
  cursor: grab;
}
.WODisplayForm span:hover {
  cursor: pointer;
}

.uploadFormModal {
  background-color: #eee;
  width: 100%;
  height: auto;
  h2 {
    margin: 10px 0;
    color: #666666;
  }
  .mainContainer {
    margin: 10px 0;
  }
  
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  textarea {
    min-height: 200px;
  }
  label,
  textarea,
  b,
  input {
    margin: 10px 0;
  }
  [type="checkbox"] {
    margin-right: 10px;
  }
}
.sortDown:after,
.sortUp:after {
  content: " ";
  position: relative;
  left: 2px;
  border: 8px solid transparent;
}

.sortDown:after {
  top: 10px;
  border-top-color: silver;
}

.sortUp:after {
  bottom: 15px;
  border-bottom-color: silver;
}

.sortDown,
.sortUp {
  padding-right: 10px;
}

@media only screen and (max-width: 768px) {
  .WODisplayForm {
    width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
    border-width: thin;
    border-right-style: solid;
    border-color: lightgrey;
  }
  .menuSidebar {
    width: 100%;
  }
  .WorkOrders {
    button {
      width: 95vw;
    }
  }
  .dmlist {
    height: 160px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-width: thin;
    border-right-style: solid;
    border-color: lightgrey;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: lightgrey;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: grey;
  }
  .slider {
    .header {
      display: contents;
    }
  }
}
