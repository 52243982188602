/* 

// some styles used in www.socket.net:

@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500);

background-color: #361f63;
font-family: "PT Sans", "Open Sans", Helvetica, sans-serif

Common screen sizes
Mobile: 360 x 640
Mobile: 375 x 667
Mobile: 360 x 720
iPhone X: 375 x 812
Pixel 2: 411 x 731
Tablet: 768 x 1024
Laptop: 1366 x 768
High-res laptop or desktop: 1920 x 1080


*/
html,
body,
.App,
#root {
  min-height: 800px;
  margin: auto 0;
}
.App {
  display: flex;
  margin: 0 8% 2% 8%;
  font-family: "PT Sans", "Open Sans", Helvetica, sans-serif;
  flex-direction: column;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  height: min-content;
  align-self: center;
}

.mainContainer {
  border-radius: 10px;
  background-color: #f5f2f7;
  h3, .header {
    padding: 5px 20px;
    background-color: #4f2471;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.flex {
  display: flex;
}
.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}
.flexSpaceAround {
  display: flex;
  justify-content: space-around;
}
b {
  font-weight: 700;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 18.72px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 13.28px;
}

.mainContentWrapper {
  margin: 2%;
  height: fit-content;
  h3 {
    font-weight: 700;
  }
}

.width5Percent {
  width: 5%;
}
.width10Percent {
  width: 10%;
}
.width20Percent {
  width: 20%;
}
.width25Percent {
  width: 25%;
}
.width35Percent {
  width: 35%;
}
.width50Percent {
  width: 50%;
}
.width60Percent {
  width: 60%;
}
.width65Percent {
  width: 65%;
}
.width70Percent {
  width: 70%;
}
.width100Percent {
  width: 100%;
}

.marginAuto {
  margin: auto;
}
.form {
  width: 100%;
  .item {
    width: 70%;
    margin: 1% auto;
    display: flex;
  }
}
.bold {
  font-weight: 700;
}
.underline {
  text-decoration: underline;
}

button:hover {
  cursor: pointer;
}
.center {
  margin: auto;
}
.align-left {
  text-align: left;
}
.orange {
  color: #f2720f;
}
[class*="sidebar"] {
  font-family: "Source Sans Pro", sans-serif;
}
.half {
  width: 50%;
}
.full {
  width: 100%;
}
.gray {
  background-color: #c8c8c8;
}
.lightgray {
  background-color: #f5f2f7;
}
.darkergray {
  background-color: #d3d3d3;
}
i {
  font-style: italic;
}
.red {
  color: #ff0000;
}
.purple {
  color: #4f2471;
}
.green {
  color: #008000;
}
.gray-text {
  color: #666666;
}
.block {
  display: block;
}
.roundButton {
  border-radius: 0.4em;
  background: #361f63;
  color: white;
  padding: 5px;
}
.padding10 {
  padding: 10px;
}
.paddingTop10 {
  padding-top: 10px;
}
.paddingBottom10 {
  padding-bottom: 10px;
}
.paddingRight10 {
  padding-right: 10px;
}
.paddingLeft10 {
  padding-left: 10px;
}
.App{
  background-color: white;
}
.fa-eye:hover, .fa-eye-slash:hover {
  background-color: #c8c8c8;
  cursor: pointer;
}
.fa-eye:active, .fa-eye-slash:active {
  animation: bubble 0.4s ease-out;
}

@keyframes bubble {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 768px) {
  .App {
    display: flex;
    margin: 0px;
    font-family: "PT Sans", "Open Sans", Helvetica, sans-serif;
    flex-direction: column;
    align-items: center;
  }
  .fullMobile {
    width: 100%;
  }
  .mainContainer {
    display: flex;
    flex-flow: column;
  }
  .mainContentWrapper {
    margin: 0px;
  }
  .form {
    width: 100%;
    .item, input, select {
      width: 100%;
    }
  }
}

.grab:hover {
  cursor: all-scroll;
}

#viewDiv {
  height: 80vh;
}

.hidden {
  display: none;
}

body{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgba(54,31,99,1);
  background: linear-gradient(0deg, rgba(54,31,99,1) 0%, rgba(255,255,255,1) 100%); 
  background-repeat: no-repeat;
}
.white {
  color: #ffffff;
}
.boxShadow{
  box-shadow: 0px 5px 5px black;
}

input, select {
  min-width: max-content;
}

.linkText {
  background: none;
  border: none;
  font-size: 1em;
  text-decoration: underline;
}
.bigmonth:hover {
  background-color: #ffffff;
  cursor: pointer;
}