.ScanAsset {
  width: 100%;
  input {
    height: 50px;
    font-size: 18px;
  }
}
.cameraSelector {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  button, ul {
    margin: 5px 0;
  }
}
.camToggleBtn {
  width: fit-content;
}
.camList {
  list-style: inside;
}
.checkboxLabel {
  display: flex;
  align-items: center;
}
.animation {
  width: 100%;
  top: 50%;
  height: 5px;
  background-color: red;
  position: absolute;
  animation-name: example;
  animation-duration: 4s;
  opacity: 1;
  animation: flickerAnimation 1s infinite;
}

.camera {
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  max-width: 80%;
  max-height: 50%;
  video {
    flex-grow: 0;
  }
}

.inputValue {
  margin-left: 10px;
}
.assetDetailWrapper {
  p {
    padding: 10px 0;
  }
}
.assignToMeLabel {
  display: flex;
  align-items: center;
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 500px) {
  .animation {
    width: 100%;
    background-color: #ff0000;
    animation-name: example;
    animation-duration: 4s;
    opacity: 1;
    animation: flickerAnimation 1s infinite;
    margin: auto;
  }
  .camera {
    margin: auto;
  }
}

.WOSearchResult{
  padding:15px;
}
