.footer {
  display: flex;
  background-color: #e6e6e6;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 0.8em;
  font-family: Arial, Helevtica, Verdana, san-serif;
  margin-top: auto;
  a {
    margin: 0 10px;
    color: #666666;
  }
  flex-direction: column;
}

.links{
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .footer {
    width: 100vw;
    display: flex;
    flex-flow: column;
  }
}
