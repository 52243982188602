.photoPage {
  video {
    max-width: 90vw;
  }
}
.uploadPhoto {
  display: flex;
  flex-direction: column;
  button {
    max-width: max-content;
  }
}
.textBoxLabel {
  margin: auto;
  font-size: 1.25em;
}

.textBoxSize {
  width: 350px;
  height: 200px;
}

.buttonSize {
  width: 80%;
  margin: auto;
  font-size: 1.25em;
}

.buttonLine {
  width: 100%;
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .textBoxSize {
    margin: auto;
    width: 80%;
    height: 200px;
  }
  .displayFlexTextBox {
    display: flex;
    flex-direction: column;
  }
  .buttonCenter {
    width: max-content;
    margin: 10px auto;
  }
}
