.Pusher {
  width: 100%;
  padding-bottom: 10px;
}
.popup {
  display: inline-block;
  width: 20%;
  min-width: 200px;
  border: 5px solid #4f2471;
  border-style: outset;
  border-radius: 10px;
  animation-name: pusherPopup;
  animation: pusher 5s;
  position: relative;
  background-color: lightgray;
  top: 15%;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  padding: 5px;
  left: 80%;
}

@keyframes pusher {
  0% {
    left: 150%;
  }
  15% {
    left: 80%;
  }
  100% {
    left: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .popup {
    width: 90%;
    max-width: 90%;
    left: 50%;
    transform: translate(-50%);
  }
  @keyframes pusher {
    0% {
      left: 150%;
    }
    15% {
      left: 50%;
      transform: translate(-50%);
    }
    100% {
      left: 50%;
      transform: translate(-50%);
    }
  }
}
