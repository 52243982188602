.navWrapper {
  margin: 10px 0;
  display: flex;
  flex-direction: column-reverse;
}
.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  img {
    max-width: 100%;
    height: auto;
    object-fit: none;
  }
}
.navBtns {
  display: flex;
  justify-content: center;
  background-color: #361f63;
  height: 36px;
  a {
    text-decoration: unset;
    padding: 10px 20px;
    color: #ffffff;
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .active {
    background-color: #c8c8c8;
  }
  .selected {
    background-color: #4f2471;
  }
}

.navItemWrapper, .hamburgerWrapper, .dropdownWrapper {
  width: 100%;
}
.hamburgerNavItem, .hamburgerLogout {
  display: none;
}

.logout {
  visibility: visible;
}

.dropdownWrapper {
  display: flex;
  // Modal is set to z-index 2 /home/gatkins/Documents/react-portal-VM/src/components/shared/ModalConfigs.jsx
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .dropdownWrapper {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .hamburgerWrapper {
    flex-flow: column;
    align-self: flex-end;
  }
  .hamburgerNavItem {
    display: flex;
    width: 40vw;
    justify-content: center;
    background-color: #361f63;
    color: white;
    padding: 2vw;
    float: right;
    text-align: center;
  }
  .navBtns {
    width: 100%;
    color: #fff;
    flex-flow: column;
    text-align: center;
    position: relative;
    display: none;
    background-color: #361f63;
  }
  .hamburgerLogout {
    display: block;
    position: relative;
  }
  .expandHamburger {
    display: flex;
    width: 40vw;
    height: auto;
  }

  .logout {
    visibility: hidden;
  }
  .dropdownWrapper {
    position: absolute;
    width: unset;
    right: 0px;
    top: 0px;
  }
  .expandHamburger, .hamburgerWrapper{
    display: flex;
  }
}