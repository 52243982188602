.navWrapper {
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column-reverse;
}
.nav {
  width: 80%;
  display: flex;
  margin:auto;
  justify-content: space-between;
  padding: 5px;
  img {
    max-width: 100%;
    height: auto;
    object-fit: none;
  }
}
.navBtns {
  display: flex;
  justify-content: center;
  background-color: #361f63;
  height: 36px;
  a {
    text-decoration: unset;
    padding: 10px 20px;
    color: #ffffff;
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  a.active {
    background-color: #c8c8c8;
  }
  .selected {
    background-color: #4f2471;
  }
}

.logout {
  visibility: visible;
  display:flex;
  flex-direction: column;
  text-align: center;
}

.settingsBtn:hover{
  cursor: pointer;
}

.settingsBtn{
  text-decoration: underline;
  color: #606060;
}

@media only screen and (max-width: 768px) {
  .logout {
    visibility: hidden;
  }
  .nav {
    display: flex;
    flex-flow: column;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    max-height: 150px;
    width: 100vw;
    a {
      align-self: flex-start;
    }
  }
  .nav p {
    display: flex;
    flex-flow: column;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .navBtns {
    width: 100%;
    color: white;
    display: none;
    flex-flow: column;
    text-align: center;
    position: relative;
    div {
      align-self: center;
      text-decoration: unset;
      padding: 10px 20px;
      color: #ffffff;
    }
  }
  .userInfo {
    display: flex;
    align-self: center;
    text-align: center;
    p {
      min-width: 33vw;
    }
  }
  .expandHamburger {
    display: flex;
  }
  .hamburgerWrapper {
    display: flex;
  }
}
