.assetList {
  display: flex;
  flex-wrap: wrap;
}
.assetInfoGroup {
  display: flex;
  padding-top: 10px;
  padding-bottom: 5px;
  max-width: 400px;
}

.assetInfo {
  padding-bottom: 5px;
}
.assetInfoLabel {
  color: #361f63;
  font-weight: bold;
  padding-right: 10px;
}

.assetInfoBorder {
  max-width: 100%;
  border-bottom: 2px solid grey; 
}

.checkButton {
  margin-top: 8%;
  margin-right: 4%;
}

.portsSlots {
  margin: 20px;
}

.wrapper {
  display: flex;
  flex-direction: row;
}
.equipmentBlock {
  width: max-content;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 400px) {
  .assetInfoGroup {
    max-width: 95%;
  }
}