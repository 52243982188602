.WorkOrder {
  margin: auto;
  min-height: 100vh;
  width: 90%;
}
.workOrderActionsTitle {
  font-size: 1.25em;
  font-weight: bold;
  padding: 10px 0;
}
.listBorderBottom {
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}
.right {
  display: flex;
  justify-content: flex-end;
}

.space {
  display: flex;
  justify-content: space-between;
}
.padding {
  padding: 5px;
}

.WorkOrderHome {
  display: flex;
  width: 100%;
}
.marginLeft20 {
  margin-left: 20px;
}

.buttonOffline {
  color: red;
}

.workOrderButtons {
  display: flex;
}
.button, .buttonOffline {
  width: 100px;
  -webkit-appearance: none;
  height: 50px;
  background-color: gainsboro;
}
.pageMargin {
  min-width: 250px;
}
.viewPDF {
  width: 100%;
  height: 100vh;
}

.flexCenter {
  flex-flow: center;
}
.inlineBlock {
  display: inline-block;
}
.arcGISMap {
  min-height: 80vh;
}

#directionMap {
  min-height: 80vh;
}
.title {
  padding: 5px 20px;
  background-color: #4f2471;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  margin: auto;
  justify-content: center;
  font-size: 1.875em;
}
.buttonPaddingTop {
  padding: 10px;
}

.PPCData {
  font-size: 0.75em;
  color: gray;
}
.flexPPC {
  display: flex;
}

.physicalPlantConfig {
  margin-left: 10px;
  padding-top: 10px;
}

.blankSpace {
  margin: right 20px;
}
.Notes {
  margin: 10px;
  font-size: 24px;
}

.statTable {
  border: 1px solid #361f63;
  display: flex;
  flex-flow: column;
  tr {
    padding: 1vw;
    display: flex;
    flex-flow: column;
  }
  td {
    padding: 1vw;
    border-bottom: 1px solid #361f63;
  }
  .leftTD {
    border-right: 1px solid #361f63;
    background-color: #361f63;
    color: white;
  }
}

@media only screen and (max-width: 1000px) {
  .pageMargin {
    margin-left: 10px;
    margin-right: 10px;
  }
  .button, .buttonOffline {
    width: 150px;
    font-size: 1em;
  }
  .physicalPlantConfig {
    width: 100%;
    font-size: 1.125em;
  }
}
@media only screen and (max-width: 320px) {
  .pageMargin {
    margin-left: 10px;
    margin-right: 10px;
  }
  .button, .buttonOffline {
    width: 100px;
    font-size: 0.875em;
  }
}

@media only screen and (min-width: 500px) {
  .WorkOrder {
    width: 100%;
  }
  .pageMargin {
    margin: auto;
  }
}